@import url("https://fonts.googleapis.com/css?family=Lato");

.fix {
	position: relative;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Lato", sans-serif;
}

h1 {
	margin: 40px 0 20px;
}

